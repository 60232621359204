var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loader,"rounded":"sm","variant":"dark","opacity":0.1}},[_c('b-card',{attrs:{"title":"Add New Color"}},[_c('validation-observer',{ref:"createColorValidator"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Category","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.categoryOptions},on:{"change":_vm.getBrands},model:{value:(_vm.category_id),callback:function ($$v) {_vm.category_id=$$v},expression:"category_id"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Brand","label-for":"brand"}},[_c('validation-provider',{attrs:{"name":"brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.brandOptions,"disabled":!_vm.category_id},on:{"change":_vm.getModels},model:{value:(_vm.brand_id),callback:function ($$v) {_vm.brand_id=$$v},expression:"brand_id"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Model","label-for":"model"}},[_c('validation-provider',{attrs:{"name":"model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"disabled":!_vm.brand_id},model:{value:(_vm.form.mobile_model_id),callback:function ($$v) {_vm.$set(_vm.form, "mobile_model_id", $$v)},expression:"form.mobile_model_id"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Enter Color name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Image","label-for":"image"}},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"image","accept":".jpg, .png, .gif .svg"},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary","type":"button"},on:{"click":_vm.clearForm}},[_vm._v(" Reset ")]),_c('b-button',{attrs:{"variant":"success","type":"submit"}},[_vm._v(" Create ")])],1)])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }